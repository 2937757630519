import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'sp-confirm-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  public loading: boolean = false;
  public type: any;
  data: any;
  message: any;
  constructor(
  ) {
    // Office.context.ui.messageParent('send');
    // Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived,async (arg: any) => {
    //   this.data=JSON.parse(arg.message)
    //   })
    this.loading = true;
    Office.context.ui.messageParent('send');
    Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived,async (arg: any) => {
      const item=JSON.parse(arg.message);
      console.log(item);
      this.data = !!item ? item : null;
      this.message = !!item ? item?.message : 'The content of active worksheet will be deleted. Do you want to continue?';
      this.type = !!item ? item?.type : 'warning';
      this.loading = false;
      })
  }
  onDismiss(): void {
    Office.context.ui.messageParent('close');
  }
  onConfirm(): void {
    Office.context.ui.messageParent('continue');
  }
}

export interface ConfirmDialogData {
  title: string;
  message: string;
  icon?: string;
  yes?: string;
  no?: string;
  maxWidth?: number;
  minWidth?: number;
}
