import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Keycloak from 'keycloak-js';
import { environment } from 'src/environments/environment';
const keycloak = new Keycloak({
  url: environment.AUTH_URL,
  realm: environment.REALMS,
  clientId:environment.CLIENT,
});
@Component({
  selector: 'app-login-dialogue',
  templateUrl: './login-dialogue.component.html',
  styleUrls: ['./login-dialogue.component.scss']
})
export class LoginDialogueComponent {
  constructor(
    // public keycloak: KeycloakService
    public route:ActivatedRoute
  ) {
  }
  ngOnInit(): void {
    keycloak.init({}).then(() => {
      keycloak.login({ idpHint: environment.IDP_HINT, redirectUri: environment.appUrl });
    });
    // keycloak
    // .init({
    //   onLoad: 'login-required',
    //   redirectUri:environment.appUrl
    // })
    // .then((authenticated) => {
     
    // })
    // .catch((err) => {
    // });
    this.route.params.subscribe(async params => {
      const token = params['token'];
      if(token!=='token')
      {
        Office.context.ui.messageParent(token);
      }
    })

  }
  // onLogin()
  // {
  //   keycloak
  //       .init({
  //         onLoad: 'login-required',
  //         redirectUri:'http://localhost:4200/'
  //       })
  //       .then((authenticated) => {
         
  //       })
  //       .catch((err) => {
  //       });
  //    this.keycloak.init({
  //     config: {
  //       url: environment.AUTH_URL,
  //       realm:environment.REALMS,
  //       clientId: environment.CLIENT,
  //     },
  //     initOptions: {
  //       onLoad: "login-required", // redirects to the login page
  //       checkLoginIframe: false,
  //       checkLoginIframeInterval: 1000,
  //       redirectUri:"https://localhost:3000"
  //     },
  //     loadUserProfileAtStartUp: true,
  //     enableBearerInterceptor: true,
  //     bearerPrefix: "Bearer",
  //     bearerExcludedUrls: ["/assets", "/clients/public"],
  //   }).then(()=>{console.log("success");}).catch((error)=>{console.log("error",error);})
    
  // }
}
