import { Component, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { ColumnMode, DatatableComponent, NgxDatatableModule, SelectionType, SortType } from '@swimlane/ngx-datatable';
import { FilterListService } from "../services/filter-list.service";
import * as moment from "moment";
import { Criteria } from "src/app/services/utility/request-utility.service";
import { ActivatedRoute } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { AddFilterlistComponent } from "../add-filterlist/add-filterlist.component";
import { MatSelectModule } from "@angular/material/select";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FilterConditionsComponent, QueryColumns } from "src/app/shared/filter-conditions/filter-conditions.component";

@Component({
  selector: "sp-saved-filterlist",
  standalone: true,
  imports: [CommonModule, NgxDatatableModule, MatButtonModule, MatIconModule, MatDialogModule, MatSelectModule, NgxMatSelectSearchModule, FormsModule],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: MAT_DIALOG_DATA, useValue: {} }
  ],
  templateUrl: "./saved-filterlist.component.html",
  styleUrls: ["./saved-filterlist.component.scss"],
})
export class SavedFilterlistComponent {
  @ViewChild(DatatableComponent)
  table!: DatatableComponent;
  columnMode: ColumnMode = ColumnMode.force;
  rows: any[] = [];
  selectedItem: any = null;
  totalElements = 0;
  pageNumber = 0;
  limit: number = 15;
  currentCriteria: Criteria = {
    filters: [],
    pager: {
      pageNumber: 0,
      pageSize: this.limit,
    },
    sorters: [{ direction: "DESC", property: "updated_date" }],
  };
  appliedFilters: any = [];
  query?: any;
  public showTooltip: boolean = false;
  sortType = SortType.single;
  filteredNameList: any = [];
  nameFilter = false;
  nameList: any = [];
  entityId: any;
  offset: number = 0;
  SelectionType = SelectionType;
  selectionType: SelectionType = SelectionType.single;
  pageSize = 15;
  constructor(
    private filterListService: FilterListService,
    private route: ActivatedRoute,
    public dialog: MatDialog

  ) { }
  headers: any[] = [
    {
      column: "name",
      name: "Name",
      canAutoResize: true,
      isSortable: true,
      width: 100,
      sortColumn: "name",
    },
    {
      column: "description",
      name: "Description",
      canAutoResize: true,
      isSortable: true,
      width: 100,
      sortColumn: "description",
    },
    {
      column: "updatedBy",
      name: "Updated By",
      canAutoResize: true,
      isSortable: true,
      width: 100,
      sortColumn: "updated_by",
    },
    {
      column: "updatedDate",
      name: "Last Updated On",
      canAutoResize: true,
      isSortable: true,
      width: 100,
      pipe: (data: any) => {
        return moment(data).format("MM/DD/YYYY HH:mm:ss");
      },
      sortColumn: "updated_date",
    },
  ];
  ngOnInit() {
    this.route.params.subscribe(async params => {
      this.entityId = params['entityId'];
    })
  }

  onItemSelected(event: any) {
    this.selectedItem = event.selected[0];
  }
  closeDialog() {
    Office.context.ui.messageParent('close');
  }

  loadFilters() {
    this.currentCriteria.filters = [
      {
        filterType: "CONDITION",
        joinType: "NONE",
        operatorType: "EQUALS",
        key: "entity_id",
        value: this.entityId,
        dataType: "number",
      },
    ];
    this.currentCriteria.filters?.push(...this.appliedFilters);
    this.filterListService
      .loadFilters(this.currentCriteria)
      .subscribe((val: any) => {
        this.rows = val.content;
        this.selectedItem = val.content[0];
        this.totalElements = val.totalElements;
        if (this.filteredNameList.length == 0) {
          this.loadAll();
        }
      });
  }

  onApply() {
    const encodeData = encodeURIComponent(JSON.stringify(this.selectedItem));
    Office.context.ui.messageParent(encodeData)
  }

  onDelete() {
    // const dialog = this.dialogService.openConfirmDialoge({
    //   message: "Do you want to delete?",
    //   title: "Delete",
    //   icon: "delete",
    // });

    // dialog.afterClosed().subscribe((result) => {
    //   if (result) {
    this.filterListService.deleteFilter(this.selectedItem.id).subscribe({
      next: (result) => {
        // this.snackbarService.success("Filter deleted successfully.");
        this.loadFilters();
      },
      error: (err) => {
        // this.snackbarService.error("Delete failed.");
      },
    });
    // }
    // });
  }

  onEdit() {
    const dialog = this.dialog.open(AddFilterlistComponent, {
      data: { item: this.selectedItem, action: "Edit" },
      width: "370px",
      height: "280px",
      disableClose: true,
    });
    dialog.afterClosed().subscribe((result) => {
      if(result.type === 'invalid-version'){
        const encodeData = encodeURIComponent(JSON.stringify({type: 'invalid-version', message: result.data}));
        Office.context.ui.messageParent(encodeData)
      }else{
        this.loadFilters();
      }
    });
  }

  onPaginate(e: any) {
    this.pageNumber = e.offset;
    const criteria: Criteria = {
      ...this.currentCriteria,
      pager: {
        pageNumber: e.offset,
        pageSize: this.limit,
      },
    };
    this.currentCriteria = criteria;
    this.loadFilters();
  }

  onFilter() {
    this.currentCriteria.filters = [];
    this.currentCriteria.sorters = [];
    this.currentCriteria.pager = {
      pageNumber: 0,
      pageSize: this.limit,
    };
    const dialogResult = this.dialog.open(FilterConditionsComponent, {
      width: "700px",
      height: "400px",
      data: {
        persistValueOnFieldChange: true,
        columns: this.getFilterColumns(this.headers),
        emptyMessage: "Please select filter criteria.",
        config: null,
        query: this.query
      },
      hasBackdrop: false
    });
    dialogResult.afterClosed().subscribe((val) => {
      if (val) {
        this.query = dialogResult.componentInstance.data.query;
        this.addDisplayNameInFilter(this.query);
        this.appliedFilters = val;
        this.appliedFilters?.length == 0 ? (this.query = null) : "";
        this.loadFilters();
      }
    });
  }

  getFilterColumns(headers: any[]): QueryColumns[] {
    return headers.map((col: any) => {
      return {
        name: col.sortColumn,
        displayName: col.name,
        dataType: "string",
        options: col?.dataType === "category" ? col.options : undefined,
      };
    });
  }

  onClearFilter() {
    this.query = null;
    this.currentCriteria.filters = [];
    this.appliedFilters = [];
    this.loadFilters();
    this.nameFilter = false;
  }
  /**
   * HANDLE THIS FUNCTION FOR ADD DISPLAY NAME IN FILTER QUERY
   * @param query any
   */
  public addDisplayNameInFilter(query: any) {
    if (query.rules) {
      query.rules.forEach((el: any) => {
        const item: any = this.headers.find(
          (elm: any) => elm.column === el.field
        );
        if (el?.rules && el?.rules.length > 0) {
          this.addDisplayNameInFilter(el); // Recursively process sub-rules
        }
        if (!!item) {
          el.displayName = item.name;
        }
        return;
      });
    }
  }

  public getToolTipTemplate(conditions: any): string {
    this.showTooltip = !!conditions;
    if (!conditions || conditions.length === 0) {
      return "";
    }
    const text: any = this.getTooltipText(conditions);
    return text;
  }
  getDisplayName(name: string) {
    var headers: any = this.headers.filter((item) => item.sortColumn === name);
    if (headers[0]?.sortColumn) {
      return headers[0]?.name;
    }
  }

  public getTooltipText(items: any): string {
    let tooltipText = "";
    if (items.condition && items.rules && items.rules.length > 0) {
      const lastItem = items.rules.length - 1;
      items.rules.forEach((rule: any, index: number) => {
        if (!rule.condition && !rule.rules) {
          const field = this.getDisplayName(rule?.field);
          const value = !!rule?.value ? rule?.value : "";
          const condition = lastItem !== index ? items.condition : "";
          tooltipText += `<strong>${field}</strong> ${rule.operator} ${value} <strong>${condition}</strong> <br>`;
        }
        if (!!rule.condition && !!rule.rules) {
          tooltipText += `(`;
          tooltipText += this.getNestedTooltipText(rule);
          tooltipText += "<br>";
        }
      });
    } else if (items.field && items.operator && items.value) {
      tooltipText += `${items.condition} <strong>${items.field}</strong> ${items.operator} ${items.value}`;
    }
    return tooltipText;
  }

  public getNestedTooltipText(items: any): string {
    let tooltipText = "";
    if (items.condition && items.rules && items.rules.length > 0) {
      const lastItem = items.rules.length - 1;
      items.rules.forEach((rule: any, index: number) => {
        if (!rule.condition && !rule.rules) {
          const field = this.getDisplayName(rule?.field);
          const value = !!rule?.value ? rule?.value : "";
          const condition = lastItem !== index ? items.condition : "";
          tooltipText += `<strong>${field}</strong> ${rule.operator} ${value} <strong>${condition}</strong>`;
          if (index < items.rules.length - 1) {
            tooltipText += "<br>";
          }
        }
        if (!!rule.condition && !!rule.rules) {
          tooltipText += `(`;
          tooltipText += this.getNestedTooltipText(rule);
        }
      });
    }
    tooltipText += ")";
    return tooltipText;
  }

  onSort(e: any) {
    const sorters = e.sorts.map((sort: any) => {
      return { direction: sort.dir.toUpperCase(), property: sort.prop };
    });
    this.currentCriteria = { ...this.currentCriteria, sorters: sorters };
    this.loadFilters();
  }

  loadAll() {
    const criteria = {
      filters: [
        {
          filterType: "CONDITION",
          joinType: "NONE",
          operatorType: "EQUALS",
          key: "entity_id",
          value: this.entityId,
          dataType: "number",
        },
      ],
      sorters: [],
      pager: {
        pageNumber: 0,
        pageSize: 15,
      },
    };
    this.filterListService.loadFilters(criteria).subscribe((val: any) => {
      val.content.forEach((el: any) => {
        this.filteredNameList.push(el.name);
      });
    });
    this.nameList = this.filteredNameList;
  }

  filterNameSelection(text: string) {
    this.filteredNameList = this.nameList.filter((item: any) => {
      return item.trim().toLowerCase().indexOf(text.trim().toLowerCase()) != -1;
    });
  }

  loadByName(event: any) {
    this.query = null;
    this.currentCriteria.filters = [];
    this.currentCriteria.pager = {
      pageNumber: 0,
      pageSize: this.limit,
    };
    this.appliedFilters = [
      {
        filterType: "CONDITION",
        joinType: "AND",
        operatorType: "EQUALS",
        key: "name",
        value: event.value,
        dataType: "string",
      },
    ];
    this.nameFilter = true;
    this.loadFilters();
  }
}
