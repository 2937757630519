import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sp-column-missing-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatNativeDateModule,
    MatMomentDateModule,
  ],
  templateUrl: './column-missing-dialog.component.html',
  styleUrls: ['./column-missing-dialog.component.scss']
})
export class ColumnMissingDialogComponent {

  data: any;
  message: any;
  messageArray: any =
    {
      "cell": "'Code' cannot be null.",
      "column": "Critical columns missing : 'Code'. Detaching from worksheet",
      "load":"The permissions have been changed. The current sheet needs to be reloaded",
      "excelClose":"You cannot access two add-ins simultaneously. Please note that Excel will be closed.",
      "offline": "You don't seems to have an active internet connection.Please check your connection and try again",
    }
  button: any;
  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(async params => {
      this.button=params['message'];
      this.message = this.messageArray[params['message']]
    })
  }
  onDismiss(): void {
    Office.context.ui.messageParent('close');
  }
  onConfirm(): void {
    Office.context.ui.messageParent('continue');
  }
}

export interface ConfirmDialogData {
  title: string;
  message: string;
  icon?: string;
  yes?: string;
  no?: string;
  maxWidth?: number;
  minWidth?: number;
}