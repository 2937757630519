<div class="container">
  <div class="logo">
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Cummins_logo.svg/1200px-Cummins_logo.svg.png"
    />
    <div>
      <div class="name">SPRICED v1.0</div>
      <div class="description">Pricing Tool</div>
    </div>
  </div>
  <div class="login">
    <button mat-button (click)="onSubmit()" class="button">SSO</button>
    <button mat-button class="button" routerLink="/localAuth">Login</button>
  </div>
</div>
