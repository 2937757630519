<div class="box">
    <label *ngIf="!data.icon" class="text-secondary-dark font-bold text-lg flexcls">
        <mat-icon style="height: 30px; width: 30px; font-size: 28px;padding-top: 2px;"
            class="text-primary-dark justify-self-center">warning</mat-icon> Warning
    </label>
    <label *ngIf="data.icon" class="text-secondary-dark font-bold text-lg flexcls">
        <mat-icon style="height: 30px; width: 30px; font-size: 28px;padding-top: 2px;"
            class="text-primary-dark justify-self-center">{{data.icon}}</mat-icon>{{data.title}}
    </label>
    <div>
        <div class="grid grid-flow-row mt-2">
            <span class="font-thin text-base">{{data?.message}}</span>
        </div>
    </div>

    <div class="btngrp">
        <button *ngIf="data.no" mat-button class="square-button" (click)="closeDialog()">
            {{data?.no}}
        </button>
        <button *ngIf="data.yes" mat-button class="square-button" (click)="onConfirm()">
            {{data?.yes}}
        </button>
    </div>
</div>