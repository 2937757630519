import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from 'src/app/shared/shared.module';
import { EntityGridService } from 'src/app/services/entity-grid.service';
import { EntityService } from 'src/app/services/entity.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmMatDialogComponent } from 'src/app/shared/confirm-mat-dialog/confirm-mat-dialog.component';
import { AddFilterlistComponent } from '../filter-list/add-filterlist/add-filterlist.component';
import { FilterListService } from '../filter-list/services/filter-list.service';
import { Observable, combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filter-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCheckboxModule,
    MatButtonModule,
    SharedModule,
    MatProgressSpinnerModule
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: MAT_DIALOG_DATA, useValue: {} }
  ],
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  public singleColumn = false;
  public defaultAttribute: any;
  public entity: any;
  public attributes: any;
  public filteredAttributeList: any;
  public hiddenAttributes: any;
  public selectedFilterAttibutes: any;
  public selectedAttibutes: any;
  public defaultCodeSetting = "namecode";
  public headers: any = [];
  public query: any;
  public currentModel: any;
  public loading: boolean = false;
  public totalColumns: number = 0;
  public data: any = { headers: [], filters: [] };
  public selHeaders: any = [];
  public rowCount: number = 0;
  public filters: any = [];
  public condition = null;
  public updateSummaryButton: boolean = false;
  edited = false;
  isUpdate = false;
  savedFilter: any;
  public contentMessage: string = '';
  public percentage: any;
  public attributeNamesToReplaceCodeWithId: any;
  public totalDomainEntity: number = 0;
  disableLoad = false;


  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private entityService: EntityService,
    private entityGridService: EntityGridService,
    public dialog: MatDialog,
    public filterListService: FilterListService
  ) {
    Office.context.ui.messageParent('send');
    Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived, async (arg: any) => {
      let data = JSON.parse(arg.message)
      if (!!data?.totalBatch) {
        const message = data?.totalBatch >= 0 ? `Loading batch ${data.currentBatch} of ${data.totalBatch}` : this.contentMessage;
        console.log(data.currentBatch, data.totalBatch, 'percentatge')
        this.contentMessage = message;
        this.cdr.detectChanges();
      }
      else {
        console.log(data);
        this.filters = data.filters;
        this.condition = data.query;
        this.selHeaders = data.headers;
        this.savedFilter = data.savedFilter || [];
        this.isUpdate = data.isUpdate;
      }
    })
  }

  async ngOnInit() {
    this.loading = true;
    this.contentMessage = 'Retrieving entity information...';
    this.route.params.subscribe(async params => {
      const modelId = +params['model_id'];
      const entityId = +params['entity_id'];
      const token = params['token'];
      localStorage.setItem('token', token);
      this.entityService.getAllModels().subscribe((el: any) => {
        this.currentModel = el.find((elm: any) => elm.id === modelId);
      });
      const { entity } = await this.getEntityById(modelId);
      if (!!entity) {
        this.entity = entity.find((el: any) => el.id === entityId);
        this.rowCount = await this.getEntityDataCount();
        this.headers = this.entityGridService.getGridHeaders(
          this.entity,
          true,
          this.defaultCodeSetting
        );

        // Handle this recreate the header with format
        const header: any = this.addDisplayNameInFilter();
        const data =
        {
          persistValueOnFieldChange: true,
          columns: this.entityGridService.getFilterColumns(header),
          emptyMessage: "Please select filter criteria.",
          displayFormat: 'codename',
          config: null,
          query: this.condition,
          save: true,
          edit: false,
          filterName: 'test',
        }
        this.query = data;
        this.hiddenAttributes = this.headers.filter((el: any) => ['id', 'validationstatus', 'validationmessage', 'inputmessage', '__ROW_IDENTIFIER', 'inputstatus'].includes(el.selectColumn) || el.systemAttribute);
        this.entity.attributes = this.entity.attributes?.filter((el: any) => !el.systemAttribute);
        this.entity.attributes = this.entity.attributes?.map((el: any) => ({
          ...el,
          selected: true
        }))
        this.attributes = this.entity.attributes;
        if (!!this.selHeaders && this.selHeaders.length > 0) {
          this.selectedAttibutes = this.selHeaders;
        } else {
          this.selectedAttibutes = this.attributes;
        }
        this.selectedFilterAttibutes = this.selectedAttibutes;
        this.totalColumns = this.selectedAttibutes.filter((el: any) => el.selected === true).length;
        this.filteredAttributeList = [{ displayName: 'All', name: 'ALL' }, ...this.attributes];
        this.defaultAttribute = this.filteredAttributeList[0];
        this.contentMessage = 'Retrieving member count...';
        this.cdr.detectChanges();
        let domainBasedIds = this.headers.filter((el: any) => el.formType === 'LOOKUP');
        if (domainBasedIds.length !== 0) {
          const uniqueReferencedTableIds = Array.from(
            new Set(domainBasedIds.map((elm: any) => elm.referencedTableId))
          );
          const data: any = new Promise((resolve) => { combineLatest([...this.getEntityDataCounts(uniqueReferencedTableIds)]).subscribe(res => resolve(res)) });
          console.log(await data);
          this.attributeNamesToReplaceCodeWithId = uniqueReferencedTableIds?.map((id: any, index: number) => {
            return { id: id, count: data.__zone_symbol__value[index].totalElements };
          });
        }
        if (!!this.attributeNamesToReplaceCodeWithId && this.attributeNamesToReplaceCodeWithId.length > 0) {
          this.headers = await this.headers.map((header: any) => ({
            ...header,
            totalElements: this.attributeNamesToReplaceCodeWithId.reduce((count: any, el: any) => {
              if (header.formType === 'LOOKUP' && (header.referencedTableId === el.id)) {
                return el.count;
              }
              return count;
            }, 0)
          }));
        }
        this.updateHeaders(this.headers);
        this.loading = false;
      }
    });
  }

  public updateHeaders(header: any) {
    const totalElements = environment.TOTALELEMENTS;
    this.totalDomainEntity = header.filter((el: any) => (el.formType === 'LOOKUP' && el.totalElements <= totalElements)).length;
  }

  public getEntityDataCounts(ids: any[]): Observable<any>[] {
    return ids.map(id =>
      // this.entityService.getEntityDataCount(id, 0, 1, []));
      this.entityService.loadLookupData(id, 0, 1, [], [{ direction: "ASC", property: "code" }]));
  }

  public async updateSummary() {
    this.updateSummaryButton = true;
    this.rowCount = await this.getEntityDataCount();
  }

  // public getEntityDataCount() {
  //   this.entityService.getEntityDataCount(this.entity?.id, 0, 1, this.filters).subscribe({
  //     next: (res: any) => {
  //       this.updateSummaryButton = false;
  //       this.rowCount = res;
  //     },
  //     error: (err) => {
  //       this.updateSummaryButton = false;
  //     }
  //   })
  // }

  public getEntityDataCount(): Promise<any> {
    return new Promise((resolve, rejects) => {
      this.entityService.getEntityDataCount(this.entity?.id, 0, 1, this.filters).subscribe(
        (count: any) => {
          console.log(count)
          this.updateSummaryButton = false;
          resolve(
            count,
          );
        },
        (err: any) => {
          this.updateSummaryButton = false;
          rejects({
            count: 0,
          });
        }
      );
    });
  }

  public openConfirmationDialog() {
    let dialogRef;
    if (this.rowCount > 150000) {
      dialogRef = this.dialog.open(ConfirmMatDialogComponent, {
        data: { message: 'You cannot load more than 150k records', no: 'Ok', type: 'matDialog' },
        width: "400px",
        height: "160px",
        disableClose: true,
      });
    }
    else {
      dialogRef = this.dialog.open(ConfirmMatDialogComponent, {
        data: { message: 'All data will be loaded without filter. Do you want to continue?', no: 'No', yes: 'Yes', type: 'matDialog' },
        width: "400px",
        height: "160px",
        disableClose: true,
      });
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'continue') {
        this.sendDataToOfficeDialog()
      }
      console.log('The dialog was closed');
    });
  }

  public addDisplayNameInFilter(query?: any) {
    const updatedHeaders = this.headers.map((item: any) => {
      const res = item.column.split(",");
      if (res.length > 1) {
        const col = res.find((el: any) => el.endsWith("_code"));
        if (!!col) {
          return { ...item, column: col };
        }
      }
      return { ...item };
    });

    const validationStatus: any = {
      dataType: "boolean",
      name: "Validation Status",
      formType: "FREE_FORM",
      column: "is_valid",
      options: undefined,
      isFilterable: true,
      referencedTableId: null,
      selectColumn: 'validationstatus',
      systemAttribute: false,
      width: 100
    };
    updatedHeaders.push(validationStatus);

    if (!!query && query.rules) {
      query.rules.forEach((el: any) => {
        const item: any = updatedHeaders.find(
          (elm: any) => elm.column === el.field
        );
        if (el?.rules && el?.rules.length > 0) {
          this.addDisplayNameInFilter(el); // Recursively process sub-rules
        }
        if (!!item) {
          el.displayName = item.name;
        }
        if (el.field === "is_valid") {
          el.displayName = "Validation Status";
        }
        return;
      });
    }
    return updatedHeaders;
  }

  public getEntityById(id: number): Promise<any> {
    return new Promise((resolve, rejects) => {
      this.entityService.getEntityByModelId(id).pipe().subscribe(
        (entity: any) => {
          resolve({
            entity,
          });
        },
        (err: any) => {
          rejects({
            entity: [],
          });
        }
      );
    });
  }

  public async loadData() {
    this.rowCount = await this.getEntityDataCount();
    const selectedAttribute = this.selectedAttibutes.filter((el: any) => el.selected === true)
    let data: any = [];
    selectedAttribute.forEach((el: any) => {
      const match = this.headers.find((elm: any) => elm.selectColumn === el.name);
      if (!!match) {
        data.push(match);
      }
      return
    })
    this.updateHeaders(data);

    this.attributeNamesToReplaceCodeWithId = this.attributeNamesToReplaceCodeWithId?.map((item: any) => {
      const entity = this.entity.attributes.find((el: any) => el.referencedTableId === item.id);
      if (!!entity) {
        return {
          ...item,
          selectColumn: entity.name
        }
      }
      return null
    })
    this.data.headers = [...data, ...this.hiddenAttributes];
    this.data.selectedAttibutes = [];
    this.data.attributeNamesToReplaceCodeWithId = this.attributeNamesToReplaceCodeWithId;
    this.data.totalDomainEntity = this.totalDomainEntity;
    if (this.attributes.length !== data.length) {
      this.data.selectedAttibutes = this.selectedAttibutes;
    }
    if (!!this.filters && this.filters.length === 0 || this.rowCount > 150000) {
      this.openConfirmationDialog()
    }
    else {
      this.disableLoad = true;
      this.sendDataToOfficeDialog();
    }
  }

  public sendDataToOfficeDialog() {
    this.loading = true;
    this.contentMessage = `Loading domain-based attributes...`;
    const encodeData = encodeURIComponent(JSON.stringify({ data: this.data, type: 'progress' }));
    Office.context.ui.messageParent(encodeData);
  }

  public getFilters(item: any) {
    this.data.filters = item.filter;
    this.data.query = item.query;
    this.filters = item.filter;
    this.edited = item.edited
  }

  public closeDialog(text?: any) {
    Office.context.ui.messageParent(text ? text : 'close');
  }

  public handleAttributeChange(attribute: any) {
    this.selectedFilterAttibutes = attribute.name !== 'ALL' ? this.selectedAttibutes.filter((el: any) => el.id === attribute.id) : this.selectedAttibutes;
  }

  public handleCheckBox(value: any, id: number) {
    this.selectedAttibutes?.map((res: any) => {
      if (res.id === id) {
        res.selected = value.checked;
      }
    })
    this.totalColumns = this.selectedAttibutes.filter((el: any) => el.selected === true).length;
  }

  selectAll() {
    // Set all checkboxes to checked state
    this.selectedAttibutes.forEach((item: any) => item.selected = true);
    this.totalColumns = this.selectedAttibutes.filter((el: any) => el.selected === true).length;
  }

  clearAll() {
    this.selectedAttibutes.forEach((item: any) => {
      if (item.displayName == 'Code' || item.displayName == 'Name') {
        item.selected = true
      }
      else {
        item.selected = false
      }
    });
    this.totalColumns = this.selectedAttibutes.filter((el: any) => el.selected === true).length;
    this.cdr.detectChanges();
  }
  searchAttribute(text: string) {
    let attributes = this.selectedAttibutes.filter((item: any) => {
      return (
        item.displayName
          .trim()
          .toLowerCase()
          .indexOf(text.trim().toLowerCase()) != -1
      );
    });
    this.filteredAttributeList = [{ displayName: 'All', name: 'ALL' }, ...attributes];
  }

  saveFilter(button: any) {
    if (button == 'update') {
      let dialogRefe: any;
      this.savedFilter.filterQuery =
        this.data.query;
      this.filterListService.editFilter(this.savedFilter).subscribe({
        next: (result: any) => {
          dialogRefe = this.dialog.open(ConfirmMatDialogComponent, {
            data: { message: 'Filter Updated successfully', icon: 'check_circle', title: 'Success' },
            width: "280px",
            height: "100px",
            disableClose: true,
          });
          setTimeout(() => {
            dialogRefe.close();
          }, 1500);
        },
        error: (err) => {
          if (err?.error?.errorCode === 'VEX-400') {
            const encodeData = encodeURIComponent(JSON.stringify({ data: err?.error?.message, type: 'invalid-version' }));
            dialogRefe.close();
            Office.context.ui.messageParent(encodeData);
          }
        },
      });
    }
    else {
      const dialogRef = this.dialog.open(AddFilterlistComponent, {
        data: { item: { entityId: this.entity.id, groupId: this.entity.groupId, filterQuery: this.data.query }, action: "Add" },
        width: "370px",
        height: "280px",
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.type === 'invalid-version') {
          dialogRef.close();
          const encodeData = encodeURIComponent(JSON.stringify({ type: 'invalid-version', message: result.data }));
          Office.context.ui.messageParent(encodeData)
        }
      });
    }

  }

}
