export const environment = {
    development: true,
    VERSION: require('../../package.json').version,
    API_DEFINITION_URL: 'https://spriced.dev.simadvisory.com/api/v1/definition',
    API_DATA_URL: 'https://spriced.dev.simadvisory.com/api/v1/data-api',
    URL: 'https://excelplugin.spriced.nrp.dev.simadvisory.com',
    AUTH_URL: 'https://auth.dev.simadvisory.com/auth',
    CLIENT: "D_SPRICED_Client",
    REALMS: "D_SPRICED",
    PAGESIZE: 5000,
    PUBLISHRECORD: 500,
    TOTALELEMENTS: 15000,
    VALIDATIONPOPUPLENGTH: 10000,
    IDP_HINT: 'saml_spriced_dev',
    appUrl:"https://excelplugin.spriced.nrp.dev.simadvisory.com"
};
