import { Component } from '@angular/core';
import { SettingsService } from './service/settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  showSystem = false;
  globalSettings: any;
  loading=false;
  constructor(private settingsService: SettingsService) {
    Office.context.ui.messageParent('send');
    Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived, async (arg: any) => {
      this.globalSettings = JSON.parse(arg.message)
      this.showSystem = this.globalSettings.settingsData.showSystem;
    })
  }

  applySettings() {
    this.loading=true;
    console.log(this.globalSettings)
    this.globalSettings.settingsData.showSystem = this.showSystem;
    if (this.globalSettings?.id) {
      this.settingsService
        .putSettings(this.globalSettings)
        .subscribe((result: any) => {
          this.globalSettings = result;
          Office.context.ui.messageParent(JSON.stringify(this.globalSettings));
        });
    }
    else {
      this.settingsService
        .setSettings(this.globalSettings)
        .subscribe((result: any) => {
          this.globalSettings = result;
          Office.context.ui.messageParent(JSON.stringify(this.globalSettings));
        });
    }
  }
  onCancel() {
    Office.context.ui.messageParent('close');
  }
}
