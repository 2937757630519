<div class="loader" *ngIf="loading">Loading...<mat-spinner></mat-spinner>
</div>
<div *ngIf="!loading">
<label class="text-secondary-dark m-2 font-bold text-lg">Settings</label>
<mat-card class="m-5">
  <mat-label class="m-2 mt-4"><strong>All Entity</strong></mat-label>
  <mat-checkbox color="primary"  [(ngModel)]="showSystem">
    Show System Attribute</mat-checkbox>
</mat-card>
<div class="footer">
  <button class="mr-2 " (click)="applySettings()">
    Apply
  </button>
  <button class="mr-2 cancel-btn" (click)="onCancel()">
    Cancel
  </button>
</div>
</div>
