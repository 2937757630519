import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  
  message = "Loading...";

  constructor(private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    Office.context.ui.messageParent('start');
    Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived, (arg: any) => {
      console.log(arg.message)
      const currentMessgage = JSON.parse(arg.message);
      this.message = !['', undefined, null].includes(currentMessgage) ? currentMessgage : this.message;
      this.cdr.detectChanges();
    })
  }

}
