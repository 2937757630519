<div class="loading" *ngIf="loading">Loading<mat-spinner></mat-spinner></div>
<div class="box" *ngIf="!loading">
  <div class="flex items-center">
    <label class="text-secondary-dark m-2 font-bold text-lg">
      <mat-icon style="height: 30px; width: 30px; font-size: 28px;padding-top: 5px;"
        class="text-primary-dark justify-self-center">warning</mat-icon>
    </label>

    <div>
      <div class="grid grid-flow-row">
        <!-- <span class="font-thin text-base">The content of active worksheet will be deleted. Do you want to continue?</span> -->
        <span class="font-thin text-base">{{message}}</span>
      </div>
    </div>
  </div>

  <div class="btngrp" *ngIf="!!data">
    <button mat-button class="square-button" *ngIf="data?.no" (click)="onDismiss()">
      {{data.no}}
    </button>
    <button mat-button class="square-button" *ngIf="data?.yes" (click)="onConfirm()">
      {{data.yes}}
    </button>
    <button mat-button class="square-button" *ngIf="data?.ok" (click)="onConfirm()">
      {{data.ok}}
    </button>
  </div>
</div>