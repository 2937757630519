import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private onlineStatus$ = new BehaviorSubject<boolean>(navigator.onLine);

  constructor() {
    const offline$ = fromEvent(window, 'offline').pipe(map(() => false));
    const online$ = fromEvent(window, 'online').pipe(map(() => true));
    merge(offline$, online$).subscribe(this.onlineStatus$);
  }

  get isOnline(): Observable<boolean> {
    return this.onlineStatus$.asObservable();
  }
}