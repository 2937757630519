<div class="head">
  <span class="p-4 text-lg">Save Filter</span>
</div>
<form [formGroup]="saveForm" (ngSubmit)="onSubmit()" class="p-4">
  <div class="flex items-center grid grid-cols-4">
    <div class="col-span-1 break-words">
      <mat-label>Name:</mat-label>
    </div>
    <div class="col-span-3">
      <mat-form-field [appearance]="'outline'" class="full-width ">
        <input matInput [maxLength]=50 [minLength]=3 matTooltipClass="custom-tooltip" autocomplete="off"
          formControlName="name" />
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="saveForm.get('name')!.invalid && (saveForm.get('name')!.dirty || saveForm.get('name')!.touched)"
    class="error mb-2">
    <div *ngIf="saveForm.get('name')!.errors?.['required']">
      Name is required.
    </div>
    <div *ngIf="saveForm.get('name')!.errors?.['minlength']">
      Name must be at least 3 characters long.
    </div>
    <div *ngIf="saveForm.get('name')!.errors?.['maxlength']">
      Name cannot be more than 50 characters long.
    </div>
    <div *ngIf="saveForm.get('name')!.errors?.['pattern']">
      Invalid name.
    </div>
  </div>

  <div class="flex items-center grid grid-cols-4">
    <div class="col-span-1 break-words">
      <mat-label>Description</mat-label>
    </div>
    <div class="col-span-3">
      <mat-form-field [appearance]="'outline'" class="full-width ">
        <input matInput [maxLength]=50 [minLength]=3 matTooltipClass="custom-tooltip" autocomplete="off"
          formControlName="description" />
      </mat-form-field>
    </div>
  </div>
  <div
    *ngIf="saveForm.get('description')!.invalid && (saveForm.get('description')!.dirty || saveForm.get('description')!.touched)"
    class="error mb-2">
    <div *ngIf="saveForm.get('description')!.errors?.['minlength']">
      Description must be at least 3 characters long.
    </div>
    <div *ngIf="saveForm.get('description')!.errors?.['maxlength']">
      Description cannot be more than 50 characters long.
    </div>
  </div>

  <div class="error">{{this.message}}</div>

  <div class="submit-btn">
  <button type="submit" mat-stroked-button class="mr-2 btn" [ngClass]="saveForm.invalid ? 'disabled' : ''"
    [disabled]="saveForm.invalid">Submit</button>
  <button type="button" mat-stroked-button class="mr-2 cancel-btn" (click)="onClose()">Cancel</button>
  </div>

</form>