import { Injectable } from '@angular/core';
import { IndexeddbService } from './indexdb.service';

@Injectable({
    providedIn: 'root',
})
export class LookupStorageService {
    storageService: IndexeddbService;
    constructor() {
        this.storageService = new IndexeddbService();
    }

    initDB() {
        this.storageService.initDB();
    }

    addStore(storeName: string, keyPath: string) {
        return this.storageService.addStore(storeName, keyPath);
    }
    addBulkData(storeName: string, data: any[]): Promise<void> {
        return this.storageService.addBulkData(storeName, data);

    }
    getAllData(storeName: string): Promise<any[]> {
        return this.storageService.getAllData(storeName);

    }
    getDataByKey(storeName: string, key: any): Promise<any> {
        return this.storageService.getDataByKey(storeName, key);

    }
    getStores() {
        return this.storageService.getStores();
    }
    closeDb() {
       return this.storageService.closeDb();
    }
    ensureDbOpen() {
        return this.storageService.ensureDbOpen();
    }
}