import { CommonModule } from '@angular/common';
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatChipEditedEvent, MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

@Component({
  selector: 'app-input-multi-tag',
  standalone: true,
  imports: [
    CommonModule, 
    MatChipsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDatepickerModule,
    MatButtonModule,
    MatInputModule,
    MatChipsModule,
    MatNativeDateModule,
    MatMomentDateModule,
  ],
  providers: [InputMultiTagComponent],
  templateUrl: './input-multi-tag.component.html',
  styleUrls: ['./input-multi-tag.component.scss']
})
export class InputMultiTagComponent {
  @ViewChild('multiInput') public multiInput!: ElementRef;
  public rule: any;
  public field: any;
  public placeholder: any;
  public type: any;
  public currentRuleValue: any[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  public selectedDate: any; // New property to track selected date

  constructor(
    private dialogRef: MatDialogRef<InputMultiTagComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputMultiTagComponent,
  ) {}

  ngOnInit(){
    if(this.data) {
      this.rule = this.data.rule;
      this.currentRuleValue = Array.isArray(this.data?.rule?.value) && this.data?.rule?.value?.length > 0 ? this.data?.rule?.value : [];
      this.type = this.data?.field?.type;
      this.placeholder = this.type !== 'date' ? `Type ${this.type} then press enter...` : 'MM/DD/YYYY';
    }
  }

  public convertDateFormat(dates: string[], toISO: boolean = true): string[] {
    return dates.map((dateStr: string) => {
      if (toISO) {
        // Convert from MM/DD/YYYY to ISO
        const [month, day, year] = dateStr.split('/').map(Number);
        const date = new Date(Date.UTC(year, month - 1, day));
        return date.toISOString();
      } else {
        // Convert from ISO to MM/DD/YYYY
        const date = new Date(dateStr);
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Pad month with leading zero
        const day = ('0' + date.getDate()).slice(-2); // Pad day with leading zero
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
      }
    });
  }


  public handleValue(event: any) {
    if(this.type === 'string') {
      const value = event.target.value;
      const regex = /^".*"$/;
      const stringValue = regex.test(value);
      this.separatorKeysCodes = value.includes('"') && !stringValue && value !== '' ? [ENTER] : [ENTER, COMMA];
      if(stringValue) {
        const event: any = {value: value};
        this.add(event, this.type);
      }
    }
  }

  /**
   * Handle this function for adding the mulit tags single input
   * @param event MatChipInputEvent
   * @param rule any
   * @param type string
   */
  public add(event: MatChipInputEvent, type: string): void {
    let value: any = type !== 'date' ? (event.value || '').trim() : event.value;
    const valueType = typeof value;
    value = valueType !== 'object' ? type === 'date' && value.includes(' ') ? value.split(" ") : value.includes('\n') ? value.split('\n') : value.includes('"') ? value : value.includes(',') ? value.replace(/"/g, '').split(',') : value : value;
    value = valueType !== 'object' && type === 'date' && Array.isArray(value) ? value?.map(item => item.replace(/,$/, '').trim()) : value;
    const itemType = Array.isArray(value);
    const matchNumbers = /^-?\d*\.?\d+$/;
    const matchDate = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/;

    if(['string', 'LOOKUP'].includes(type)) {
      // Add our values
      if (value !== '') {
        value = itemType ? value.filter((el: any) => el !== "") : value;
        const duplicateValue = this.currentRuleValue?.find((el: any) => el === value);
        if(!duplicateValue) {
          itemType ? this.currentRuleValue?.push(...value) : this.currentRuleValue?.push(value);
        }
      }
    }

    if(type === 'number') {
      const valuesArray = itemType ? value : value.includes(' ') ? value.split(' ') : [value];
      // Add our values
      if (valuesArray.length > 0) {
        for (let i=0; i<valuesArray.length; i++){
          const item = valuesArray[i].trim();
          const match = matchNumbers.test(item);
          if(match) {
          const duplicateValue = this.currentRuleValue?.find((el: any) => el === item);
            if(!duplicateValue) {          
              this.currentRuleValue?.push(item);
            }
          }
        }
      }
    }

    if(type === 'date') {
      // Normalize `value` to an array, if it's a string wrap it in an array
      const valuesArray = itemType ? value : [value];

      // Iterate over the array of date values
      valuesArray.forEach((val: string) => {
        if (val !== '' && matchDate.test(val)) {
          val = val.trim();
          const parsedDate = new Date(val);
          // const parsedDate = new Date(value);
          // Check if the date is valid: parsedDate.getTime() must not be NaN
          const isValidDate = !isNaN(parsedDate.getTime()) && this.isValidFormatDate(parsedDate);
          // const matchDate = this.matchingDateType(value);
          if (isValidDate) {
            const formattedDate = this.formatDateMMDDYYYY(parsedDate);
            const duplicateValue = this.currentRuleValue?.find((el: any) => el === value);
            if(!duplicateValue) {
              this.currentRuleValue?.push(formattedDate);
            }
          } else {
            console.error('Invalid date input');
          }
        }
      });
    }

    // Clear the input value
    event?.chipInput?.clear();
    this.multiInput.nativeElement.value = '';
  }

  /**
   * Handle this function for remove the multi tag values
   * @param item any
   * @param rule any
   */
  public remove(item: any): void {
    const index = this.currentRuleValue?.findIndex((el: any) => el === item);

    if (index !== -1) {
      this.currentRuleValue?.splice(index, 1);
    }
  }

  /**
   * Handle this function for edit the multi tag values
   * @param item any
   * @param rule any
   * @param event MatChipEditedEvent
   */
  public edit(item: any, rule: any, event: MatChipEditedEvent) {
    const value = event.value.trim();
    const currenValIndex = this.currentRuleValue?.findIndex((elm: any) => elm === item);
    const updatedValue = this.currentRuleValue?.includes(value) ? item : value;
    if(rule?.type === 'date'){
      const parsedDate = new Date(value);
      if(!isNaN(parsedDate.getTime()) && this.isValidFormatDate(parsedDate)){
        this.currentRuleValue[currenValIndex] = updatedValue;
      }
    } else {
      this.currentRuleValue[currenValIndex] = updatedValue;
    }
  }

  // Function to check if the date is realistically within range
  public isValidFormatDate(date: Date): boolean {
    // Check for reasonable year range, for example between 1900 and 2100
    const year = date.getFullYear();
    return year >= 1900 && year <= 2100;
  }

  // Function to format a date as MM/DD/YYYY
  public formatDateMMDDYYYY(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');  // getMonth() is zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${month}/${day}/${year}`;
  }

 public onSubmit() {
  this.rule.value = this.currentRuleValue;
  this.dialogRef.close({ event: "Update", data: this.rule });
 }
 
 public onCancel() {
  this.dialogRef.close(null);
 }

}
