import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AppUtilityService {
    public removeDuplicatesBasedOnKey(array: any, key: any) {
        return [...new Map(array.map((obj: any) => [obj[key], obj])).values()];
    }
    public columnIndexFromAddress(address: string) {
        // Extract column part from the address

        let columnPartAddress = address.match(/[A-Za-z]+/);

        if (columnPartAddress) {
            let columnPart = columnPartAddress[0];
            // Convert column letters to numeric index
            let columnIndex = 0;
            for (let i = 0; i < columnPart.length; i++) {
                columnIndex = columnIndex * 26 + (columnPart.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
            }

            // Adjust to 0-based index
            return columnIndex - 1;
        }
        else {
            return -1;
        }


    }

    public indicesFromLetterToLetter(startLetter: string, endLetter: string) {
        startLetter = startLetter.toUpperCase(); // Convert start letter to uppercase
        endLetter = endLetter.toUpperCase(); // Convert end letter to uppercase
    
        const indices = []; // Initialize an array to store indices
    
        // Function to convert letter to index
        const letterToIndex = (letter: string) => {
            let index = 0;
            for (let j = 0; j < letter.length; j++) {
                index = index * 26 + (letter.charCodeAt(j) - 'A'.charCodeAt(0) + 1);
            }
            return index - 1; // To make 'A' as 0, 'B' as 1, etc.
        };
    
        const startIndex = letterToIndex(startLetter);
        const endIndex = letterToIndex(endLetter);
    
        // Function to convert index to letter
        const indexToLetter = (index: number) => {
            let result = '';
            let current = index + 1; // Reverse the index adjustment
            while (current > 0) {
                current--; // Adjust for 1-based index
                result = String.fromCharCode((current % 26) + 'A'.charCodeAt(0)) + result;
                current = Math.floor(current / 26);
            }
            return result;
        };
    
        // Loop through from start index to end index
        for (let i = startIndex; i <= endIndex; i++) {
            indices.push(i);
        }
        
        return indices;
    }

  public extractColumnLetters(address: string): { firstColumn: string, secondColumn: string } {
        // Split the address into start and end parts
        const parts = address.split(':');
    
        // Extract the first and second parts (which include column letters)
        const startPart = parts[0];
        const endPart = parts[1] || '';
    
        // Extract column letters and row numbers
        const startColumn = startPart.match(/[A-Z]+/g)![0]; // Get the column letters from the start part
        const endColumn = endPart.match(/[A-Z]+/g)?.[0] || startColumn; // Get the column letters from the end part or use startColumn if endPart is empty
    
        return { firstColumn: startColumn, secondColumn: endColumn };
    }

}