import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'app-add-annotation',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatRadioModule, MatCheckboxModule, MatTableModule],
  templateUrl: './add-annotation.component.html',
  styleUrls: ['./add-annotation.component.scss']
})
export class AddAnnotationComponent implements OnInit {
  public dataSource: any = [];
  public displayedColumns: string[] = ['demo-position', 'code', 'attribute', 'value', 'annotation'];

  constructor(){}

  ngOnInit(): void {
      const data = [
        {code: 12, attribute: 'Manifest', value: 'test'},
        {code: 5, attribute: 'New member', value: 'test1'}
      ]
      this.dataSource = data;
  }

  public publish()
  {
    Office.context.ui.messageParent('publish');
  }

  public closeDialog() {
    Office.context.ui.messageParent('close');
  }

}
