<div class="container">
    <div class="heading"> Add annotation to provide details about transactions(optional) </div>

    <div class="title mt-2">
        <mat-radio-button value="1"> <span> Use same annotations for all changes </span> </mat-radio-button>
        <div class="add-annotation-input mt-2">
            <textarea id="message" rows="2"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Add annotation here..."></textarea>
        </div>
    </div>

    <div class="title mt-4">
        <mat-radio-button class="mat-radio-container" value="2"> <span> Review changes and provide annotations
                indivisually </span> </mat-radio-button>

        <div class="annotations-box mt-2">
            <table mat-table [dataSource]="dataSource" class="annotaiton-table mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="demo-position">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element"> </td>
                </ng-container>
              
                <!-- Column -->
                <ng-container matColumnDef="code">
                  <th mat-header-cell *matHeaderCellDef> Code </th>
                  <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                </ng-container>
              
                <!-- Attribute Column -->
                <ng-container matColumnDef="attribute">
                  <th mat-header-cell *matHeaderCellDef> Attribute </th>
                  <td mat-cell *matCellDef="let element"> {{element.attribute}} </td>
                </ng-container>

                <!-- Value Column -->
                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef> Value </th>
                    <td mat-cell *matCellDef="let element"> {{element.value}} </td>
                  </ng-container>
              
                <!-- Annotation Column -->
                <ng-container matColumnDef="annotation">
                  <th mat-header-cell *matHeaderCellDef> Annotations </th>
                  <td mat-cell *matCellDef="let element"> <input matInput /> </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
        </div>
    </div>

    <div class="btn-grp">
        <div class="pop-up"> <mat-checkbox value="0"> Do not show this dialog box again. </mat-checkbox> </div>
        <div>
            <button mat-stroked-button color="primary" class="mr-2" (click)="publish()">Publish</button>
            <button mat-stroked-button color="primary" class="mr-2" (click)="closeDialog()">Cancel</button>
        </div>
    </div>

</div>