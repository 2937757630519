import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-token-auth',
  templateUrl: './token-auth.component.html',
  styleUrls: ['./token-auth.component.scss']
})
export class TokenAuthComponent {
  // public handleToken = new Subject<string>();
  public token: any;
  username = '';
  password = '';
  error: boolean=false;
  constructor(
    private ngZone: NgZone,
    private authService: AuthService,
    private route: Router
  ) {
 
  }

  onSubmit() {
    this.authService.login(this.username, this.password).subscribe({ next: (result:any)  => {
      if (result?.access_token) {
        this.error=false
        localStorage.setItem('token', result.access_token);
        this.updateAccessToken(result.access_token);
        this.ngZone.run(() => {
          this.route.navigate(['/']);
        })
      }
    },
    error: (err) => {
      if (err.status === 401) {
        this.error=true
      }
    },
  })
  }

  public updateAccessToken(token: any) {
    this.authService.updateToken(token);
  }
  onCancel() {
    this.route.navigateByUrl('/');
  }
}
