import { Injectable } from '@angular/core';

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable({

  providedIn: 'root'

})

export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(

    next: ActivatedRouteSnapshot,

    state: RouterStateSnapshot): boolean {

    if (!this.authService.isLoggedIn()) {

      this.router.navigate(['/landing']); // go to login if not authenticated

      return false;

    }
    return true;

  }

}