import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BroadcastService {
    private channel!: BroadcastChannel;
    private proceed: boolean = true;

    constructor() {
        this.createBroadcastChannel(); // Create the channel initially
    }

    private createBroadcastChannel() {
        this.channel = new BroadcastChannel('excel_addin_channel');
    }

    public async initializeBroadcastChannel(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.createBroadcastChannel(); // Ensure channel exists

            // Notify other instances that this add-in is opened
            this.channel.postMessage('addinOpened');

            // Listen for messages from other instances
            this.channel.onmessage = (event) => {
                if (event.data === 'addinOpened') {
                    // Another instance is already opened
                    this.channel.postMessage('close');
                }
                else if (event.data === 'addinClosed') {
                    this.proceed = true;
                }
                if (event.data === 'close') {
                    this.proceed = false;
                    // this.closeWorkbookWithMessage();
                }
            };

            setTimeout(() => { resolve(this.proceed); }, 500);
        });
    }

    public closeBroadcastChannel() {
        if (this.channel) {
            this.channel.postMessage('addinClosed');
            this.channel.close();
        }
    }

    public reopenBroadcastChannel() {
        this.createBroadcastChannel();
    }

    private async closeWorkbookWithMessage() {
        Office.context.ui.displayDialogAsync(
            `${environment.URL}/#/missing/excelClose`,
            { height: 30, width: 40 },
            (item) => {
                const dialog = item.value;
                dialog.addEventHandler(
                    Office.EventType.DialogMessageReceived,
                    async (arg: any) => {
                        if (arg.message === "continue") {
                            await Excel.run(async (context: any) => {
                                context.workbook.close(Excel.CloseBehavior.skipSave);
                            });
                        }
                    });
            });
    }
}