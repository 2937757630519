import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-confirm-mat-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule
  ],
  templateUrl: './confirm-mat-dialog.component.html',
  styleUrls: ['./confirm-mat-dialog.component.scss']
})
export class ConfirmMatDialogComponent implements OnInit {
  public data: any;

  constructor(
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ConfirmMatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {
    if (!!dialogData) {
      this.data = dialogData;
    }
  }

  ngOnInit(): void {

  }

  closeDialog() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close('continue');
  }

}
