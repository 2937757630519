import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { FilterConditionsComponent } from './filter-conditions/filter-conditions.component';
import { MatIconModule } from '@angular/material/icon';
import {
  ColumnMode,
  NgxDatatableModule,
  SelectionType,
  SortType,
} from "@swimlane/ngx-datatable";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ColumnMissingDialogComponent } from './column-missing-dialog/column-missing-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmMatDialogComponent } from './confirm-mat-dialog/confirm-mat-dialog.component';
import { LookupDialogComponent } from './lookup-dialog/lookup-dialog.component';
import { LoaderComponent } from './loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SettingsComponent } from './settings/settings.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatCardModule} from "@angular/material/card"

@NgModule({
  declarations: [
    LoaderComponent,
    SettingsComponent
  ],
  imports: [
    CommonModule,
    FilterConditionsComponent,
    SharedRoutingModule,
    MatIconModule,
    NgxDatatableModule,
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    ConfirmDialogComponent,
    MatDialogModule,
    ColumnMissingDialogComponent,
    ConfirmMatDialogComponent,
    LookupDialogComponent,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatCardModule,
    FormsModule,
  ],
  providers:[
    MatDialogModule
  ],
  exports: [FilterConditionsComponent,ConfirmDialogComponent,ColumnMissingDialogComponent
  ]
})
export class SharedModule { }
