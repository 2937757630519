<div class="loader" *ngIf="loading"> {{ contentMessage }}<mat-spinner></mat-spinner>
    <div class="loader-note" *ngIf="loading"> <span class="font-semibold">Note: </span> <span class="italic">Please do not close until the process is completed.</span> </div>
</div>

<ng-container *ngIf="!loading">
    <div class="header mb-2 ml-2 font-medium"> Choose attributes to be dispalyed as columns in the worksheet. Filter
        the data to be dispalyed in the rows. </div>
    <div class="left-section">
        <div class="attribute-section">


            <div class="attribute-list">
                <div class="header"> Attributes </div>
                <!-- <div class="title font-medium"> Columns </div> -->
                <div class="mat_dropdown">
                    <mat-label class="label">Attribute:</mat-label>
                    <mat-form-field appearance="outline" class="form-field">
                        <!-- <mat-label>Model:</mat-label> -->
                        <mat-select [panelClass]="'entity-dropdown'" matNativeControl
                            (valueChange)="handleAttributeChange($event)" [value]="defaultAttribute">
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" [ngModelOptions]="{standalone:true}"
                                    ngModel (ngModelChange)="searchAttribute($event)"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option value="" class="option">--Select--</mat-option>
                            <mat-option *ngFor="let model of filteredAttributeList" [value]="model">
                                {{model.displayName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <ul>
                    <li [title]="item.displayName" *ngFor="let item of selectedFilterAttibutes"> <mat-checkbox
                            class="mat-checkbox" [disabled]="item.displayName=='Code' || item.displayName=='Name' "
                            [ngModel]="item.selected" class="example-margin" (change)="handleCheckBox($event, item.id)">
                            {{item.displayName}} </mat-checkbox> </li>
                </ul>
            </div>

            <div class="btn-grp">
                <button mat-stroked-button class="select-all" (click)="selectAll()">Select All</button>
                <button mat-stroked-button (click)="clearAll()">Clear All</button>
            </div>
        </div>

        <div class="right-section">
            <div class="title font-medium"> Rows </div>
            <div class="filter-container">
                <app-filter-conditions (filters)="getFilters($event)" [data]="query"></app-filter-conditions>
            </div>

            <div class="summary-container">
                <div class="heading"> Update Summary </div>
                <div class="summary">
                    <div class="entity detail">
                        <div> <span class="title"> Model: </span> <span class="content"> {{currentModel.displayName}}
                            </span> </div>
                        <div> <span class="title"> Entity: </span> <span class="content"> {{entity.displayName}} </span>
                        </div>
                    </div>
                    <div class="entity count">
                        <div> <span class="title"> Rows: </span> <span class="content"> {{rowCount}} </span> </div>
                        <div> <span class="title"> Columns: </span> <span class="content"> {{totalColumns}} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="submit-btn">
        <button mat-stroked-button color="primary" class="mr-2" *ngIf="this.edited && isUpdate" [disabled]="!this.data.query" [ngClass]="this.data.query ? '' : 'disabled'"  (click)="saveFilter('update')">Update Filter</button>
        <button mat-stroked-button color="primary" class="mr-2" [disabled]="!this.data.query" [ngClass]="this.data.query ? '' : 'disabled'"  (click)="saveFilter('save')">Save Filter</button>
        <button mat-stroked-button color="primary" class="mr-2 update-btn" [ngClass]="updateSummaryButton ? 'disabled' : ''" (click)="updateSummary()" [disabled]="updateSummaryButton"><span class="label">Update Summary <mat-spinner *ngIf="updateSummaryButton"></mat-spinner></span></button>
        <button mat-stroked-button color="primary" class="load-btn" [ngClass]="totalColumns == 0 || disableLoad ? 'disabled' : ''" (click)="loadData()" [disabled]="totalColumns == 0">Load Data</button>
        <button mat-stroked-button color="primary" class="cancel-btn" (click)="closeDialog()">Cancel</button>
    </div>
</ng-container>