<div class="box h-full">
  <div style="width: 100%"
    class="head bg-primary-default h-10 rounded-t-md flex flex-row items-center justify-between pl-5 pr-5">
    <span class="float-left text-white font-medium">Saved Filters</span>
    <div class="flexcls">
      <span class="float-left font-medium mr-2">Name</span>
      <mat-form-field>
        <mat-select (selectionChange)="loadByName($event)" placeholder="Select">
          <mat-option>
            <ngx-mat-select-search placeholderLabel="Search" ngModel
              (ngModelChange)="filterNameSelection($event)"></ngx-mat-select-search>
          </mat-option>
          <mat-option class="option" *ngFor="let item of filteredNameList" [value]="item">{{ item }}</mat-option>
        </mat-select>
      </mat-form-field>

      <button title="Delete Filter" class="text-white filter-btn h-full flex flex-row cursor-pointer pl-2 pr-1"
        [disabled]="
          this.selectedItem === null ||
          this.selectedItem === undefined"
          [ngClass]="
          this.selectedItem === null ||
          this.selectedItem === undefined
            ? 'disabled'
            : ''"
          (click)="onDelete()">
        <mat-icon class="mt-auto">delete</mat-icon>
      </button>

      <button title="Edit Filter" class="text-white filter-btn h-full flex flex-row cursor-pointer pl-2 pr-1"
        [disabled]="
          headers.length === 0 ||
          this.selectedItem === null ||
          this.selectedItem === undefined"
          [ngClass]="
          headers.length === 0 ||
          this.selectedItem === null ||
          this.selectedItem === undefined
            ? 'disabled'
            : ''"
           (click)="onEdit()">
        <mat-icon class="mt-auto">edit</mat-icon>
      </button>

      <button title="Filter" class="text-white filter-btn h-full flex flex-row cursor-pointer pl-2 pr-1" 
        (click)="onFilter()">
        <mat-icon class="mt-auto">filter_list</mat-icon>
      </button>

      <button title="Clear Filter" class="text-white filter-btn h-full flex flex-row cursor-pointer pl-2 pr-1"
        [ngClass]="
          this.appliedFilters.length == 0
            ? 'disabled'
            : ''
        " [disabled]="
          this.appliedFilters.length == 0
        " (click)="onClearFilter()">
        <mat-icon class="mt-auto">filter_list_off</mat-icon>
      </button>
    </div>
  </div>

  <ngx-datatable class="material w-full h-full overflow-y: auto" #table
    style="height: calc(100% - 30px); width: 100%; overflow-y: hidden" [columnMode]="columnMode" [headerHeight]="20"
    [footerHeight]="40" [rowHeight]="30" [rows]="rows" [limit]="pageSize" [scrollbarV]="true" [externalPaging]="true"
    [externalSorting]="true" [virtualization]="false" [selectionType]="SelectionType.single" [count]="totalElements"
    [offset]="offset" [sortType]="sortType" [selected]="!!selectedItem ? [selectedItem] : []"
    (page)="onPaginate($event)" (select)="onItemSelected($event)" (sort)="onSort($event)">
    <ngx-datatable-column name="Name" prop="name" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="Description" prop="description" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="Updated By" prop="updatedBy" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="Last Updated On" prop="updatedDate" [width]="200"></ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
        let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
        <div style="padding: 5px 5px">
          <div>
            <!-- Rows: {{ rowCount !== "NaN" ? rowCount : 0 }} | Page Size: 30 | 
            Current Page: {{ curPage !== "NaN" ? curPage : 0 }} -->
            Rows: {{ rowCount !== "NaN" ? rowCount : 0 }} | Page Size:
            {{ pageSize !== "NaN" ? pageSize : 0 }} | Current Page:
            {{ curPage !== "NaN" ? curPage : 0 }}
          </div>
        </div>
        <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
          [size]="pageSize" [count]="totalElements" [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)">
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>
<div class="p-4 submit-btn">
  <button class="mr-2 btn" mat-stroked-button [ngClass]="!selectedItem ? 'disabled' : ''" [disabled]="!selectedItem"
    (click)="onApply()">
    Apply
  </button>
  <button class="mr-2 cancel-btn" mat-stroked-button (click)="closeDialog()">
    Cancel
  </button>
</div>