import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

Office.initialize = function () {
 
  ﻿Office.onReady((reason) => {
    const platform = platformBrowserDynamic();
    platform.bootstrapModule(AppModule);
    Office.context?.document?.settings?.set("Office.AutoShowTaskpaneWithDocument", true);
    Office.context?.document?.settings?.saveAsync(result => {
      if (result?.status === Office.AsyncResultStatus?.Succeeded) {
        console.log('Settings saved successfully.');
      } else {
        console.error('Error saving settings:', result.error);
      }
    });
  });
};
