<div class="container">
  <div class="logo">
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Cummins_logo.svg/1200px-Cummins_logo.svg.png"
    />
    <div>
      <div class="name">SPRICED v1.0</div>
      <div class="description">Pricing Tool</div>
    </div>
  </div>

  <div class="login">
    <div class="token-box">
      <div class="token-input">
        <input
          #tokenInput
          matInput
          [(ngModel)]="username"
          placeholder="Username"
        />
      </div>
      <div class="token-input">
        <input
          #tokenInput
          type="password"
          matInput
          [(ngModel)]="password"
          placeholder="Password"
        />
      </div>

      <button mat-button (click)="onSubmit()" class="button">Login</button>
      <button mat-button (click)="onCancel()" class="button">Cancel</button>
      <div class="error" *ngIf="error">Invalid UserName or Password</div>
    </div>
  </div>
</div>